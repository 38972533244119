
import { defineComponent, inject, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "@/store/index";

import * as echarts from "echarts";
import {
  AdminHome,
} from "@/apis/interface/IAdminHome";
import { LocalStorage } from "storage-manager-js";

export default defineComponent({
  name: "Info",
  setup() {
    const { state } = useStore();

    let echart1Div = ref(null);
    let echart2Div = ref(null);

    const dataDetail = reactive<AdminHome>(new AdminHome(null));
    const loadData = async () => {
      let object = LocalStorage.get("COUNT-DATA") as any;

      dataDetail.countResultList = object.countResultList;
      dataDetail.userActivityResultList = object.userActivityResultList || [];
      dataDetail.formResultList = object.formResultList || [];
      renderA();
      renderB();
    };

    const VAL = (k: string) => {
      return dataDetail.countResultList?.find((item) => item.key == k)?.value;
    };

    const renderA = () => {
      const dataList = dataDetail.userActivityResultList;
      let a = echart1Div.value as unknown;
      if (a) {
        const echart1 = echarts.init(a as HTMLElement);
        echart1.setOption({
          grid: {
            top: "2%",
            left: "3%",
            right: "3%",
            bottom: "5%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: dataList?.map((item) => item.timePoint),
            axisLabel: {
              rotate: 15,
            },
          },
          yAxis: {
            type: "value",
            minInterval: 1,
          },
          series: [
            {
              data: dataList?.map((item) => item.count),
              type: "line",
            },
          ],
        });
      }
    };

    const renderB = () => {
      const dataList = dataDetail.formResultList;
      let b = echart2Div.value as unknown;
      if (b) {
        const echart2 = echarts.init(b as HTMLElement);
        echart2.setOption({
          grid: {
            top: "2%",
            left: "3%",
            right: "3%",
            bottom: "5%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: dataList?.map((item) => item.timePoint),
            axisLabel: {
              rotate: 15,
            },
          },
          yAxis: {
            type: "value",
            minInterval: 1,
          },
          series: [
            {
              data: dataList?.map((item) => item.count),
              type: "line",
            },
          ],
        });
      }
    };

    onMounted(() => {
      loadData();
    });

    return {
      admin: ref(state.authData.admin),
      echart1Div,
      echart2Div,
      ...toRefs(dataDetail),
      VAL,
    };
  },
});

import { RespBody, RespBodyList, RespBodyPager } from "@/apis/interface/Resp";
import { PageParams } from "./PageParams";
import { basemodel } from "./basemodel";

export class AdminHome extends basemodel {
  constructor(data: any | null) {
    super(data);
    super.setDefaultFormModelName("AdminHomeModel");
  }

  countResultList?: Array<ICountResult> = [];

  userActivityResultList?: Array<IChartResult> = [];

  formResultList?: Array<IChartResult> = [];
}

export class AdminHomeCount {
  form_need_what_DAY?: number;
  form_need_what_ALL?: number;
  form_need_what_UNTREATED?: number;
  form_service_general_DAY?: number;
  form_service_general_ALL?: number;
  form_service_general_UNTREATED?: number;
  form_service_job_apply_DAY?: number;
  form_service_job_apply_ALL?: number;
  form_service_job_apply_UNTREATED?: number;
  form_service_job_invite_DAY?: number;
  form_service_job_invite_ALL?: number;
  form_service_job_invite_UNTREATED?: number;
  service_activity_DAY?: number;
  service_activity_ALL?: number;
  service_general_DAY?: number;
  service_general_ALL?: number;
  user_favorite_DAY?: number;
  user_favorite_ALL?: number;
  company_DAY?: number; 
  company_ALL?: number; 
  user_DAY?: number; 
  user_ALL?: number;
  user_ACTIVE?: number;
  // [key: string]: string;
}
export interface ICountResult {
  key: keyof AdminHomeCount;
  value: number;
}

export interface IChartResult {
  count: number;
  timePoint: string;
}

// API URL： /api/v1/management/admin-home
// 管理端-首页相关 | 管理端-首页接口
export interface IAdminHome {
  // 获取 首页数量
  // GET("/cout")
  getCount(): Promise<RespBody<AdminHome>>
}
